import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Matsu-Motsu.Work', // e.g: 'Name | Developer'
  lang: 'jp', // e.g: en, es, fr, jp
  description: 'Welcome to my website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Shinya Matsumoto',
  subtitle: "I'm the Full Stack Engineer.",
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: [
    'はじめまして、ラーメン好きエンジニアの松本慎也です🍜',
    '主な使用言語はPHPで、4年ほどプログラマーしております。',
    '基本的に何でもできます。何でもやります。',
  ],
  paragraphTwo: '詳しい職歴は、「RESUME」からご覧くださいませ。',
  profile: {
    name: 'Matsu-Motsu ( 松本慎也 )',
    age: 26,
    region: '千葉県四街道市',
    region_url: 'https://goo.gl/maps/AbELuKFhfWHHKhKr7',
    hobby: '愛犬と戯れ、ラーメン',
  },
  paragraphThree: '',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project1.png',
    title: 'My Portoforio site',
    info: 'ポートフォリオサイト',
    info2: [
      'Framework: Gatsby.js',
      'Hosting: Netlify, Github',
      'Library: React, Three.js, CANNON.js',
      'API: GraphQL',
    ],
    url: 'https://matsu-motsu.work',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 's.matsumoto@matsu-motsu.me',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'codepen',
      url: 'https://codepen.io/matsu-motsu',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/matsu-motsu',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
